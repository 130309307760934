<template>
  <v-app>

    <v-app-bar color="#4682b4"  density="compact">
      <v-app-bar-title>T-Zapp</v-app-bar-title>
      <v-btn to="/">Home</v-btn>
      <v-btn to="/my_projects">My Projects</v-btn>
      <v-btn to="/settings">Settings</v-btn>
    </v-app-bar>

    <router-view></router-view>
  </v-app>
</template>

<script >

//const links = ["Home", "Projects", "News", "Login"]
export default {
// todo: feed template from script
  name: "App",
  data() {
    return {
      links: ["Home", "Projects", "News", "Login"],
    };
  },
};

document.addEventListener('swUpdated', (event) => {
  const registration = event.detail;

  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  }
});

</script>

<style>

</style>